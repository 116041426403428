import axios from "axios";
import { defineStore } from "pinia";

export const SettingsStore = defineStore('setting-store', {
  state: () => ({
    item: {},
    tempItem: {},
    error: {},
    default: false,
    type: 'email',
  }),

  getters: {
    isChanged(state) {
      if (JSON.stringify(state.item) !== JSON.stringify(state.tempItem)) {
        return true;
      }
      return false;
    },
  },

  actions: {

    intValue(type) {
      let values = {}

      switch (type) {
        case 'gmail':
          values = { name: '', email: '', password: '', type_email: type }
          break;
        case 'host_email':
          values = { name: '', type: 'IMAP', host: '', email: '', password: '', port: 993, encryption: true, interval: 5, auto_delete: 0, smtp_host: '', smtp_username: '', smtp_password: '', smtp_port: 465, smtp_encryption: true, type_email: type }
          break;

        case 'line':
          values = { access_token: '' }
          break;

        case 'zalo':
          values = { app_id: '', app_secret: '', access_token: '', refresh_token: '' }
          break;

        case 'facebook':
          values = { page_id:'', app_id: '', app_secret: '', access_token: '' }
          break;

        default:
          break;
      }
      return values
    },

    async getSetting() {
      this.error = {}
      const response = await axios.get(`/api/setting/${this.type}`);
      this.item = response.data.item
      this.default = response.data.default
      if (this.type == 'email' && this.default == true) {
        //set and get default value for email
        this.item.type = 'host_email'
      }
      if (this.default == true) {
        this.item = this.intValue(this.type)
      }
      this.tempItem = Object.assign({}, this.item)
    },

    async saveSetting() {
      let datas = {}
      await axios.post(`/api/setting`, { item: this.item, type: this.type }, { timeout: 60000 })
        .then(response => {
          datas = response.data;
        })
        .catch(error => {
          datas = 'error'
        });
      return datas
    },

    async deleteSetting() {
      const response = await axios.delete(`/api/setting/${this.type}`)
      return response.data ?? []
    },

  }
});