<script setup>
import { ref, defineExpose } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'

const isOpenDialog = ref(false)

const show = ($value = true) => {
  isOpenDialog.value = $value
}
defineExpose({ show })
const emit = defineEmits(['clear-setting'])
</script>
<template>
  <TransitionRoot appear :show="isOpenDialog" as="template">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-[335px] transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all border-red-500 border-t-4">
              <div class="mt-2 text-left">
                <p class="text-red-500">※メール送受信機能が全て停止します</p>
              </div>
              <div class="mt-3 text-left">
                <p class="text-slate-900 pl-4 text-md">
                  ・メールの自動取得停止<br />
                  ・問い合わせ者への自動返信停止<br />
                  ・オペレーターへの通知停止<br />
                  ・管理者への通知停止<br />
                </p>
              </div>

              <div class="mt-3 text-left">
                <p class="text-slate-900 font-bold">本当に、解除してよろしいですか？</p>
              </div>

              <div class="mt-8 flex mx-auto justify-center gap-4">
                <button type="button" class="btn-delete shadow" @click="emit('clear-setting')">解除する</button>
                <button type="button" class="btn-cancel shadow" @click="(isOpenDialog = false)">キャンセル</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
