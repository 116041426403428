<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import PopupResetFacebook from './PopupResetFacebook.vue';
const refPopupResetFacebook = ref(null)

import { SettingsStore } from "store/settings";
const settingsStore = SettingsStore();

onMounted(async () => {
  settingsStore.type = 'facebook'
  //get list template
  await settingsStore.getSetting()
})

const clearSetting = async () => {
  $root.$overlay.show()
  const response = await settingsStore.deleteSetting()
  if (response.code == 200) {
    refPopupResetFacebook.value.show(false)

    //reload sample value
    settingsStore.getSetting()
  }
  else {
    $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
  }
  $root.$overlay.hide()
}

const save = async () => {
  checkForm()
  if (Object.keys(settingsStore.error).length > 0) {
    $root.notify({ group: 'top', message: t('require_field_notice'), type: 'error' })
    return
  }
  //save function here
  $root.$overlay.show()
  settingsStore.saveSetting().then(response => {

    let _msg = ''
    let type = 'warning'
    if (response.code == 200 && response.errors.length == 0) {
      _msg = t('msg_success')
      type = 'success'
      //get config
      settingsStore.getSetting()
    }
    else {
      _msg = t('msg_save_error')
    }
    $root.notify({ group: 'top', message: _msg, type: type })

    $root.$overlay.hide()
  })


}
const checkForm = () => {
  settingsStore.error = {}

  if (!settingsStore.item.page_id) {
    settingsStore.error.page_id = true
  }
  if (!settingsStore.item.app_id) {
    settingsStore.error.app_id = true
  }
  if (!settingsStore.item.app_secret) {
    settingsStore.error.app_secret = true
  }
  if (!settingsStore.item.access_token) {
    settingsStore.error.access_token = true
  }
}

</script>
<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between">
      <div class="flex text-2xl font-bold text-blue-910 items-center">Facebook設定</div>
      <div>
        <button class="btn-delete shadow" @click="refPopupResetFacebook.show()"
          v-if="!settingsStore.default">Facebook送受信設定を解</button>
      </div>
    </div>

    <div class="flex bg-white flex-col divide-y divide-slate-200 rounded border drop-shadow text-sm border-slate-400">
      <div class="p-1 flex items-center bg-slate-150">
        Facebook情報と設定
      </div>

      <div class="p-2 flex gap-2 items-center">
        <div class="w-48 flex-none ">Page ID</div>
        <div class="grow">
          <input type="text" class="rounded h-7 border px-2 w-full" v-model.trim="settingsStore.item.page_id"
            :class="settingsStore.error.page_id ? 'border-red-500' : 'border-default'" />
        </div>
        <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
      </div>

      <div class="p-2 flex gap-2 items-center">
        <div class="w-48 flex-none ">Application ID</div>
        <div class="grow">
          <input type="text" class="rounded h-7 border px-2 w-full" v-model.trim="settingsStore.item.app_id"
            :class="settingsStore.error.app_id ? 'border-red-500' : 'border-default'" />
        </div>
        <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
      </div>

      <div class="p-2 flex gap-2 items-center">
        <div class="w-48 flex-none">App secret</div>
        <div class="grow">
          <input type="text" class="rounded h-7 border px-2 w-full" v-model.trim="settingsStore.item.app_secret"
            :class="settingsStore.error.app_secret ? 'border-red-500' : 'border-default'" />
        </div>
        <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
      </div>

      <div class="p-2 flex gap-2 items-center">
        <div class="w-48 flex-none">Access token</div>
        <div class="grow">
          <input type="text" class="rounded h-7 border px-2 w-full" v-model.trim="settingsStore.item.access_token"
            :class="settingsStore.error.access_token ? 'border-red-500' : 'border-default'" />
        </div>
        <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
      </div>

      <div class="p-2 flex items-center justify-center">
        <button class="btn-save shadow" @click="save()">保存</button>
      </div>

    </div>

    <PopupResetFacebook ref="refPopupResetFacebook" @clear-setting="clearSetting" />
  </div>
</template>
